exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cenik-js": () => import("./../../../src/pages/cenik.js" /* webpackChunkName: "component---src-pages-cenik-js" */),
  "component---src-pages-dod-js": () => import("./../../../src/pages/dod.js" /* webpackChunkName: "component---src-pages-dod-js" */),
  "component---src-pages-galerie-js": () => import("./../../../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lokalita-js": () => import("./../../../src/pages/lokalita.js" /* webpackChunkName: "component---src-pages-lokalita-js" */),
  "component---src-pages-marketing-kampan-js": () => import("./../../../src/pages/marketing/kampan.js" /* webpackChunkName: "component---src-pages-marketing-kampan-js" */),
  "component---src-pages-o-projektu-js": () => import("./../../../src/pages/o-projektu.js" /* webpackChunkName: "component---src-pages-o-projektu-js" */),
  "component---src-pages-room-management-js": () => import("./../../../src/pages/room-management.js" /* webpackChunkName: "component---src-pages-room-management-js" */),
  "component---src-pages-vyber-bytu-js": () => import("./../../../src/pages/vyber-bytu.js" /* webpackChunkName: "component---src-pages-vyber-bytu-js" */),
  "component---src-templates-detail-js": () => import("./../../../src/templates/detail.js" /* webpackChunkName: "component---src-templates-detail-js" */),
  "component---src-templates-house-detail-js": () => import("./../../../src/templates/houseDetail.js" /* webpackChunkName: "component---src-templates-house-detail-js" */)
}

